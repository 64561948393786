var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "rootVM" } }, [
    _vm._m(0),
    _c("div", { staticClass: "panel panel-flat" }, [
      _c(
        "div",
        {
          staticClass: "panel-body",
          staticStyle: { padding: "40px", margin: "0" }
        },
        [
          _c(
            "form",
            {
              attrs: { action: "#" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-md-3",
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      width: "300px",
                      "margin-left": "10px"
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v(_vm._s(_vm.detailsFieldMap.siteNm))]),
                      _c(
                        "div",
                        { staticClass: "select-wrapper" },
                        [
                          _c(
                            "select2",
                            {
                              attrs: { disabled: _vm.isSiteDisabled },
                              model: {
                                value: _vm.searchVM.siteNm,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchVM, "siteNm", $$v)
                                },
                                expression: "searchVM.siteNm"
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("전체")
                              ]),
                              _vm._l(_vm.options.siteOptions, function(
                                row,
                                index
                              ) {
                                return _c(
                                  "option",
                                  {
                                    key: "search-site-" + index,
                                    domProps: { value: row.siteNm }
                                  },
                                  [_vm._v(_vm._s(row.siteNm))]
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-md-3",
                    staticStyle: { width: "300px", "margin-right": "10px" }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.detailsFieldMap.cctvInstallPlaceCont))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVM.cctvInstallPlaceCont,
                            expression: "searchVM.cctvInstallPlaceCont"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "startInput",
                          placeholder: _vm.detailsFieldMap.cctvInstallPlaceCont,
                          maxlength: _vm.maxLength.cctvInstallPlaceCont
                        },
                        domProps: { value: _vm.searchVM.cctvInstallPlaceCont },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.searchVM,
                              "cctvInstallPlaceCont",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-md-3",
                    staticStyle: { width: "330px", "margin-left": "10px" }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.detailsFieldMap.cctvTypeDvsnCd))
                      ]),
                      _c(
                        "div",
                        { staticClass: "select-wrapper" },
                        [
                          _c(
                            "select2",
                            {
                              model: {
                                value: _vm.searchVM.cctvTypeDvsnCd,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchVM, "cctvTypeDvsnCd", $$v)
                                },
                                expression: "searchVM.cctvTypeDvsnCd"
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("전체")
                              ]),
                              _vm._l(
                                _vm.options.cctvTypeDvsnCdOptions,
                                function(item, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: "search-cctv-type-" + index,
                                      domProps: { value: item.cd }
                                    },
                                    [_vm._v(" " + _vm._s(item.cdNm) + " ")]
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-md-3", staticStyle: { width: "200px" } },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.detailsFieldMap.useYn))
                        ]),
                        _c(
                          "select2",
                          {
                            attrs: { options: _vm.options.ynOptions },
                            model: {
                              value: _vm.searchVM.useYn,
                              callback: function($$v) {
                                _vm.$set(_vm.searchVM, "useYn", $$v)
                              },
                              expression: "searchVM.useYn"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("전체")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._m(1)
              ])
            ]
          )
        ]
      )
    ]),
    _c(
      "div",
      [
        _c("div", { staticClass: "mb-10 text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-labeled bg-teal mx-sm-1",
              attrs: { type: "button" },
              on: { click: _vm.downloadExcel }
            },
            [_vm._m(2), _vm._v(" 엑셀다운로드 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-labeled bg-primary",
              attrs: { type: "button" },
              on: { click: _vm.startCreate }
            },
            [_vm._m(3), _vm._v(" 추가 ")]
          )
        ]),
        _c("KendoGrid", {
          ref: "grid",
          attrs: {
            "auto-bind": false,
            "api-url": _vm.apiUrl.pageListApi,
            columns: _vm.gridColumns,
            "apply-search-condition": _vm.applySearchStateOnGridLoad
          },
          on: { "selected-row-item-changed": _vm.selectedRowItemChanged }
        }),
        _c("div", { staticClass: "mt-10 mb-15 text-right" })
      ],
      1
    ),
    _c(
      "form",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEditMode || _vm.isCreateMode,
            expression: "isEditMode || isCreateMode"
          }
        ],
        staticClass: "form-horizontal form-validate-jquery",
        attrs: { id: "detailsForm", action: "#" }
      },
      [
        _c("div", { staticClass: "panel panel-flat" }, [
          _c("div", { staticClass: "panel-heading" }, [
            _vm._m(4),
            _c("div", { staticClass: "heading-elements" }, [
              _c("ul", { staticClass: "icons-list" }, [
                _c("li", [
                  _c("a", {
                    attrs: { "data-action": "collapse" },
                    on: { click: _vm.pannelHidden }
                  })
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "panel-body" }, [
            _c("div", { staticClass: "row in-panel-body" }, [
              _c("fieldset", [
                _vm._m(5),
                _c(
                  "div",
                  { staticClass: "col-lg-6 inputDiv1" },
                  [
                    _vm.isEditMode
                      ? _c("jarvis-field", {
                          attrs: {
                            label: _vm.detailsFieldMap.cctvId,
                            required: "true",
                            disabled: !_vm.isCreateMode,
                            field: "detailsItem.cctvId",
                            "data-vv-name": "detailsItem.cctvId"
                          },
                          model: {
                            value: _vm.detailsItem.cctvId,
                            callback: function($$v) {
                              _vm.$set(_vm.detailsItem, "cctvId", $$v)
                            },
                            expression: "detailsItem.cctvId"
                          }
                        })
                      : _vm._e(),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value:
                            _vm.validationRule.detailsItem.cctvInstallPlaceCont,
                          expression:
                            "validationRule.detailsItem.cctvInstallPlaceCont"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvInstallPlaceCont,
                        id: "firstInputCreate",
                        field: "detailsItem.cctvInstallPlaceCont",
                        required: "true",
                        "data-vv-name": "detailsItem.cctvInstallPlaceCont",
                        maxLength: _vm.maxLength.cctvInstallPlaceCont
                      },
                      model: {
                        value: _vm.detailsItem.cctvInstallPlaceCont,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvInstallPlaceCont", $$v)
                        },
                        expression: "detailsItem.cctvInstallPlaceCont"
                      }
                    }),
                    _vm.isCreateMode
                      ? _c(
                          "jarvis-field",
                          {
                            attrs: {
                              label: _vm.detailsFieldMap.siteNm,
                              field: "detailsItem.siteNm",
                              required: "true"
                            }
                          },
                          [
                            _c(
                              "select2",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value:
                                      _vm.validationRule.detailsItem.siteId,
                                    expression:
                                      "validationRule.detailsItem.siteId"
                                  }
                                ],
                                attrs: {
                                  "data-vv-name": "detailsItem.siteNm",
                                  options: _vm.options.siteOptions
                                },
                                model: {
                                  value: _vm.detailsItem.siteId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.detailsItem, "siteId", $$v)
                                  },
                                  expression: "detailsItem.siteId"
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("선택")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isCreateMode
                      ? _c("jarvis-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.validationRule.detailsItem.cctvId,
                              expression: "validationRule.detailsItem.cctvId"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.siteNm,
                            disabled: true,
                            "data-vv-name": "detailsItem.siteNm"
                          },
                          model: {
                            value: _vm.detailsItem.siteNm,
                            callback: function($$v) {
                              _vm.$set(_vm.detailsItem, "siteNm", $$v)
                            },
                            expression: "detailsItem.siteNm"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "jarvis-field",
                      {
                        attrs: {
                          label: _vm.detailsFieldMap.cctvInstallDvsnCd,
                          field: "detailsItem.cctvInstallDvsnCd",
                          required: "true"
                        }
                      },
                      [
                        _c(
                          "select2",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  _vm.validationRule.detailsItem
                                    .cctvInstallDvsnCd,
                                expression:
                                  "validationRule.detailsItem.cctvInstallDvsnCd"
                              }
                            ],
                            attrs: {
                              "data-vv-name": "detailsItem.cctvInstallDvsnCd",
                              options: _vm.options.cctvInstallDvsnCdOptions
                            },
                            model: {
                              value: _vm.detailsItem.cctvInstallDvsnCd,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.detailsItem,
                                  "cctvInstallDvsnCd",
                                  $$v
                                )
                              },
                              expression: "detailsItem.cctvInstallDvsnCd"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "jarvis-field",
                      {
                        attrs: {
                          label: _vm.detailsFieldMap.useYn,
                          field: "detailsItem.useYn",
                          required: "true"
                        }
                      },
                      [
                        _c(
                          "select2",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.validationRule.detailsItem.useYn,
                                expression: "validationRule.detailsItem.useYn"
                              }
                            ],
                            attrs: {
                              "data-vv-name": "detailsItem.useYn",
                              options: _vm.options.ynOptions
                            },
                            model: {
                              value: _vm.detailsItem.useYn,
                              callback: function($$v) {
                                _vm.$set(_vm.detailsItem, "useYn", $$v)
                              },
                              expression: "detailsItem.useYn"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "jarvis-field",
                      { attrs: { label: _vm.detailsFieldMap.cctvKindDvsnCd } },
                      [
                        _c(
                          "select2",
                          {
                            attrs: {
                              options: _vm.options.cctvKindDvsnCdOptions
                            },
                            model: {
                              value: _vm.detailsItem.cctvKindDvsnCd,
                              callback: function($$v) {
                                _vm.$set(_vm.detailsItem, "cctvKindDvsnCd", $$v)
                              },
                              expression: "detailsItem.cctvKindDvsnCd"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("선택")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvResolution,
                          expression:
                            "validationRule.detailsItem.cctvResolution"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvResolution,
                        field: "detailsItem.cctvResolution",
                        "data-vv-name": "detailsItem.cctvResolution",
                        maxLength: _vm.maxLength.cctvResolution
                      },
                      model: {
                        value: _vm.detailsItem.cctvResolution,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvResolution", $$v)
                        },
                        expression: "detailsItem.cctvResolution"
                      }
                    }),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvConnUrl,
                          expression: "validationRule.detailsItem.cctvConnUrl"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvConnUrl,
                        field: "detailsItem.cctvConnUrl",
                        "data-vv-name": "detailsItem.cctvConnUrl",
                        maxLength: _vm.maxLength.cctvConnUrl
                      },
                      model: {
                        value: _vm.detailsItem.cctvConnUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvConnUrl", $$v)
                        },
                        expression: "detailsItem.cctvConnUrl"
                      }
                    }),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvConnPort,
                          expression: "validationRule.detailsItem.cctvConnPort"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvConnPort,
                        field: "detailsItem.cctvConnPort",
                        "data-vv-name": "detailsItem.cctvConnPort",
                        maxLength: _vm.maxLength.cctvConnPort
                      },
                      model: {
                        value: _vm.detailsItem.cctvConnPort,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvConnPort", $$v)
                        },
                        expression: "detailsItem.cctvConnPort"
                      }
                    }),
                    _c("jarvis-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: _vm.validationRule.detailsItem.cctvPlayUrl,
                          expression: "validationRule.detailsItem.cctvPlayUrl"
                        }
                      ],
                      attrs: {
                        label: _vm.detailsFieldMap.cctvPlayUrl,
                        field: "detailsItem.cctvPlayUrl",
                        "data-vv-name": "detailsItem.cctvPlayUrl",
                        maxLength: _vm.maxLength.cctvPlayUrl
                      },
                      model: {
                        value: _vm.detailsItem.cctvPlayUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.detailsItem, "cctvPlayUrl", $$v)
                        },
                        expression: "detailsItem.cctvPlayUrl"
                      }
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isEditMode,
                            expression: "isEditMode"
                          }
                        ]
                      },
                      [
                        _c("jarvis-field", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEditMode,
                              expression: "isEditMode"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.regDtm,
                            disabled: "true",
                            field: "detailsItem.regDtm",
                            value: _vm._f("toDisplayDateTime")(
                              _vm.detailsItem.regDtm
                            )
                          }
                        }),
                        _c("jarvis-field", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEditMode,
                              expression: "isEditMode"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.regUser,
                            disabled: "true",
                            field: "detailsItem.regUser",
                            value: _vm.detailsItem.regUser
                          }
                        }),
                        _c("jarvis-field", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEditMode,
                              expression: "isEditMode"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.updDtm,
                            disabled: "true",
                            field: "detailsItem.updDtm",
                            value: _vm._f("toDisplayDateTime")(
                              _vm.detailsItem.updDtm
                            )
                          }
                        }),
                        _c("jarvis-field", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isEditMode,
                              expression: "isEditMode"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.updUser,
                            disabled: "true",
                            field: "detailsItem.updUser",
                            value: _vm.detailsItem.updUser
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-lg-6",
                    staticStyle: { display: "flex", "flex-direction": "column" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-offset-1",
                        staticStyle: {
                          border: "1px solid #ddd",
                          "padding-top": "40px",
                          "margin-bottom": "35px",
                          "padding-right": "10px"
                        }
                      },
                      [
                        _c("div", [
                          _c("i", {
                            staticClass: "icon-question4",
                            staticStyle: {
                              color: "gray",
                              position: "absolute",
                              top: "20px"
                            },
                            on: {
                              mouseover: function($event) {
                                return _vm.info($event)
                              },
                              mouseleave: function($event) {
                                return _vm.closeInfo($event)
                              }
                            }
                          }),
                          _vm._m(6),
                          _c(
                            "div",
                            [
                              _c(
                                "jarvis-field",
                                {
                                  attrs: {
                                    label:
                                      _vm.detailsFieldMap.safeObjectDistigshYn,
                                    field: "detailsItem.safeObjectDistigshYn",
                                    required: "true"
                                  }
                                },
                                [
                                  _c(
                                    "select2",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            _vm.validationRule.detailsItem
                                              .safeObjectDistigshYn,
                                          expression:
                                            "validationRule.detailsItem.safeObjectDistigshYn"
                                        }
                                      ],
                                      attrs: {
                                        required: true,
                                        "data-vv-name":
                                          "detailsItem.safeObjectDistigshYn",
                                        options: _vm.options.ynOptions
                                      },
                                      model: {
                                        value:
                                          _vm.detailsItem.safeObjectDistigshYn,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.detailsItem,
                                            "safeObjectDistigshYn",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailsItem.safeObjectDistigshYn"
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("선택")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c(
                                "jarvis-field",
                                {
                                  attrs: {
                                    label:
                                      _vm.detailsFieldMap.objectNmDisplayYn,
                                    field: "detailsItem.objectNmDisplayYn",
                                    required: "true"
                                  }
                                },
                                [
                                  _c(
                                    "select2",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            _vm.validationRule.detailsItem
                                              .objectNmDisplayYn,
                                          expression:
                                            "validationRule.detailsItem.objectNmDisplayYn"
                                        }
                                      ],
                                      attrs: {
                                        required: true,
                                        "data-vv-name":
                                          "detailsItem.objectNmDisplayYn",
                                        options: _vm.options.ynOptions
                                      },
                                      model: {
                                        value:
                                          _vm.detailsItem.objectNmDisplayYn,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.detailsItem,
                                            "objectNmDisplayYn",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailsItem.objectNmDisplayYn"
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("선택")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c(
                                "jarvis-field",
                                {
                                  attrs: {
                                    label: _vm.detailsFieldMap.sgmtDisplayYn,
                                    field: "detailsItem.sgmtDisplayYn",
                                    required: "true"
                                  }
                                },
                                [
                                  _c(
                                    "select2",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            _vm.validationRule.detailsItem
                                              .sgmtDisplayYn,
                                          expression:
                                            "validationRule.detailsItem.sgmtDisplayYn"
                                        }
                                      ],
                                      attrs: {
                                        required: true,
                                        "data-vv-name":
                                          "detailsItem.sgmtDisplayYn",
                                        options: _vm.options.ynOptions
                                      },
                                      model: {
                                        value: _vm.detailsItem.sgmtDisplayYn,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.detailsItem,
                                            "sgmtDisplayYn",
                                            $$v
                                          )
                                        },
                                        expression: "detailsItem.sgmtDisplayYn"
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("선택")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-offset-1",
                        staticStyle: {
                          border: "1px solid #ddd",
                          "margin-bottom": "35px",
                          "padding-right": "10px"
                        }
                      },
                      [
                        _vm._m(7),
                        _c("jarvis-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.validationRule.detailsItem.mdserverId,
                              expression:
                                "validationRule.detailsItem.mdserverId"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.mdserverId,
                            required: "true",
                            field: "detailsItem.mdserverId",
                            "data-vv-name": "detailsItem.mdserverId",
                            maxLength: _vm.maxLength.mdserverId
                          },
                          model: {
                            value: _vm.detailsItem.mdserverId,
                            callback: function($$v) {
                              _vm.$set(_vm.detailsItem, "mdserverId", $$v)
                            },
                            expression: "detailsItem.mdserverId"
                          }
                        }),
                        _c(
                          "jarvis-field",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value:
                                  _vm.validationRule.detailsItem.mlEngineId,
                                expression:
                                  "validationRule.detailsItem.mlEngineId"
                              }
                            ],
                            staticClass: "ml-engine-field",
                            attrs: {
                              label: _vm.detailsFieldMap.mlEngineId,
                              field: "detailsItem.mlEngineId",
                              "data-vv-name": "detailsItem.mlEngineId"
                            },
                            model: {
                              value: _vm.detailsItem.mlEngineId,
                              callback: function($$v) {
                                _vm.$set(_vm.detailsItem, "mlEngineId", $$v)
                              },
                              expression: "detailsItem.mlEngineId"
                            }
                          },
                          [
                            _vm.detailsItem.useYn == "1"
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c("div", { staticClass: "form-control" }, [
                                    _vm._v(_vm._s(_vm.detailsItem.mlEngineId))
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn btn-primary",
                                      on: { click: _vm.openSelectMlEngine }
                                    },
                                    [_c("i", { staticClass: "fa fa-search" })]
                                  )
                                ])
                              : _vm._e(),
                            _vm.detailsItem.useYn == "0" ||
                            !_vm.detailsItem.useYn
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c("div", {
                                    staticClass: "form-control",
                                    staticStyle: { cursor: "not-allowed" },
                                    attrs: { disabled: true }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { disabled: true }
                                    },
                                    [_c("i", { staticClass: "fa fa-search" })]
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "jarvis-field",
                          {
                            attrs: {
                              label: _vm.detailsFieldMap.cctvTypeDvsnCd,
                              field: "detailsItem.cctvTypeDvsnCd",
                              required: "true"
                            }
                          },
                          [
                            _c(
                              "select2",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value:
                                      _vm.validationRule.detailsItem
                                        .cctvTypeDvsnCd,
                                    expression:
                                      "validationRule.detailsItem.cctvTypeDvsnCd"
                                  }
                                ],
                                attrs: {
                                  "data-vv-name": "detailsItem.cctvTypeDvsnCd",
                                  options: _vm.options.cctvTypeDvsnCdOptions
                                },
                                model: {
                                  value: _vm.detailsItem.cctvTypeDvsnCd,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.detailsItem,
                                      "cctvTypeDvsnCd",
                                      $$v
                                    )
                                  },
                                  expression: "detailsItem.cctvTypeDvsnCd"
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("선택")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-offset-1",
                        staticStyle: {
                          border: "1px solid #ddd",
                          "margin-bottom": "35px",
                          "padding-right": "10px"
                        }
                      },
                      [
                        _vm._m(8),
                        _c("jarvis-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value:
                                _vm.validationRule.detailsItem.monResolution,
                              expression:
                                "validationRule.detailsItem.monResolution"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.monResolution,
                            field: "detailsItem.monResolution",
                            "data-vv-name": "detailsItem.monResolution"
                          },
                          model: {
                            value: _vm.detailsItem.monResolution,
                            callback: function($$v) {
                              _vm.$set(_vm.detailsItem, "monResolution", $$v)
                            },
                            expression: "detailsItem.monResolution"
                          }
                        }),
                        _c("jarvis-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: _vm.validationRule.detailsItem.monQlty,
                              expression: "validationRule.detailsItem.monQlty"
                            }
                          ],
                          attrs: {
                            label: _vm.detailsFieldMap.monQlty,
                            field: "detailsItem.monQlty",
                            "data-vv-name": "detailsItem.monQlty"
                          },
                          model: {
                            value: _vm.detailsItem.monQlty,
                            callback: function($$v) {
                              _vm.$set(_vm.detailsItem, "monQlty", $$v)
                            },
                            expression: "detailsItem.monQlty"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ]),
            _c("div", [
              _vm._m(9),
              !_vm.isEditMode
                ? _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled mx-sm-1",
                        attrs: { type: "button" },
                        on: { click: _vm.closeDetails }
                      },
                      [_vm._m(10), _vm._v(" 닫기 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled bg-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.createData }
                      },
                      [_vm._m(11), _vm._v(" 저장 ")]
                    )
                  ])
                : _vm._e(),
              _vm.isEditMode
                ? _c("div", { staticClass: "text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled mx-sm-1",
                        attrs: { type: "button" },
                        on: { click: _vm.closeDetails }
                      },
                      [_vm._m(12), _vm._v(" 닫기 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled bg-warning mx-sm-1",
                        attrs: { type: "button" },
                        on: { click: _vm.deleteData }
                      },
                      [_vm._m(13), _vm._v(" 삭제 ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-labeled bg-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.updateData }
                      },
                      [_vm._m(14), _vm._v(" 저장 ")]
                    )
                  ])
                : _vm._e()
            ]),
            _vm.debug
              ? _c("span", [
                  _vm._v(" isCreateMode: " + _vm._s(_vm.isCreateMode) + " "),
                  _c("br"),
                  _vm._v(" isEditMode: " + _vm._s(_vm.isEditMode) + " "),
                  _c("br"),
                  _vm._v(
                    " detailsItemOriginal: " +
                      _vm._s(_vm.detailsItemOriginal) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(" detailsItem: " + _vm._s(_vm.detailsItem) + " "),
                  _c("br"),
                  _vm._v(" errors: " + _vm._s(_vm.errors) + " "),
                  _c("br")
                ])
              : _vm._e()
          ])
        ])
      ]
    ),
    _c(
      "div",
      {
        ref: "mlEngineSelectPopup",
        staticClass: "popup ml-engine-select-popup"
      },
      [
        _c("div", {
          staticClass: "background",
          on: { click: _vm.closeMlEngineSelectPopup }
        }),
        _c("div", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "top" }, [
            _vm._v(" ML 엔진 선택 "),
            _c("i", {
              staticClass: "fa fa-times",
              on: { click: _vm.closeMlEngineSelectPopup }
            })
          ]),
          _c("div", { staticClass: "middle" }, [
            _c("div", { staticClass: "ml-engine-list" }, [
              _c("label", [_vm._v("ML 엔진")]),
              _c("div", { ref: "mlEngineListTable", staticClass: "table" }, [
                _vm._m(15),
                _c(
                  "div",
                  { ref: "mlEngineTbody", staticClass: "tbody" },
                  _vm._l(_vm.mlEngineList, function(mlEngine, i) {
                    return _c(
                      "div",
                      {
                        key:
                          "ml-engine-select-popup-" +
                          mlEngine.mlEngineId +
                          "-" +
                          i,
                        class:
                          "tr " +
                          (i == _vm.selectedMlEngineIdx ? "selected" : ""),
                        on: {
                          click: function($event) {
                            return _vm.clickMlEngine(i)
                          }
                        }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(mlEngine.mlEngineId))]),
                        _c("span", [_vm._v(_vm._s(mlEngine.mlEngineConnPort))]),
                        _c("span", [_vm._v(_vm._s(mlEngine.dtctMlModelDtlNm))]),
                        _c("span", [
                          _vm._v(_vm._s(mlEngine.classifyMlModelDtlNm))
                        ]),
                        _c("span", [_vm._v(_vm._s(mlEngine.connectionCount))]),
                        _c("span", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              on: {
                                click: function($event) {
                                  return _vm.clickEditMlEngineButton(
                                    $event,
                                    mlEngine
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-pencil-alt" })]
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ]),
            _c("div", { staticClass: "cctv-list" }, [
              _c("label", [_vm._v("연결된 CCTV")]),
              _c("div", { staticClass: "table" }, [
                _vm._m(16),
                _c(
                  "div",
                  { ref: "mlEngineCctvTbody", staticClass: "tbody" },
                  _vm._l(_vm.mlEngineCctvList, function(cctv) {
                    return _c(
                      "div",
                      {
                        key: "ml-engine-select-popup-" + cctv.cctvId,
                        staticClass: "tr"
                      },
                      [
                        _c("span", [_vm._v(_vm._s(cctv.siteNm))]),
                        _c("span", [_vm._v(_vm._s(cctv.cctvId))]),
                        _c("span", [_vm._v(_vm._s(cctv.cctvNm))])
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "bottom" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: { click: _vm.clickShowMlModelButton }
              },
              [_vm._v("ML 모델 관리")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                on: { click: _vm.clickAddMlEngineButton }
              },
              [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" ML 엔진 추가 ")]
            ),
            !_vm.mlEngineCctvList.length && _vm.selectedMlEngineIdx >= 0
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    on: { click: _vm.clickDelMlEngineButton }
                  },
                  [
                    _c("i", { staticClass: "fa fa-trash" }),
                    _vm._v(" ML 엔진 삭제 ")
                  ]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  disabled:
                    _vm.selectedMlEngineIdx < 0 ||
                    _vm.maxConnectionCount == _vm.mlEngineCctvList.length
                },
                on: { click: _vm.clickSelectMlEngineButton }
              },
              [_vm._v(" 선택 ")]
            )
          ])
        ])
      ]
    ),
    _c(
      "div",
      { ref: "mlEngineEditPopup", staticClass: "popup ml-engine-edit-popup" },
      [
        _c("div", {
          staticClass: "background",
          on: { click: _vm.closeMlEngineEditPopup }
        }),
        _c("div", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "top" }, [
            _vm._v(
              " ML 엔진 " + _vm._s(_vm.isEditMlEngine ? "수정" : "추가") + " "
            ),
            _c("i", {
              staticClass: "fa fa-times",
              on: { click: _vm.closeMlEngineEditPopup }
            })
          ]),
          _c(
            "div",
            { staticClass: "middle" },
            [
              _c("label", [_vm._v("탐지 모델")]),
              _c(
                "select2",
                {
                  attrs: { value: _vm.selectedDtctModelId },
                  model: {
                    value: _vm.selectedDtctModelId,
                    callback: function($$v) {
                      _vm.selectedDtctModelId = $$v
                    },
                    expression: "selectedDtctModelId"
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                  _vm._l(_vm.options.dtctModelListOptions, function(option) {
                    return _c(
                      "option",
                      {
                        key: "ml-edit-detect-" + option.value,
                        domProps: { value: option.value }
                      },
                      [_vm._v(" " + _vm._s(option.text) + " ")]
                    )
                  })
                ],
                2
              ),
              _c(
                "select2",
                {
                  attrs: {
                    value: _vm.mlEngineDetailsItem.dtctMlModelDtlId,
                    disabled: !_vm.selectedDtctModelId
                  },
                  model: {
                    value: _vm.mlEngineDetailsItem.dtctMlModelDtlId,
                    callback: function($$v) {
                      _vm.$set(_vm.mlEngineDetailsItem, "dtctMlModelDtlId", $$v)
                    },
                    expression: "mlEngineDetailsItem.dtctMlModelDtlId"
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                  _vm._l(_vm.options.dtctModelDetailListOptions, function(
                    option
                  ) {
                    return _c(
                      "option",
                      {
                        key: "ml-edit-detect-detail-" + option.value,
                        domProps: { value: option.value }
                      },
                      [_vm._v(" " + _vm._s(option.text) + " ")]
                    )
                  })
                ],
                2
              ),
              _c("label", [_vm._v("분류 모델")]),
              _c(
                "select2",
                {
                  attrs: { value: _vm.selectedClassifyModelId },
                  model: {
                    value: _vm.selectedClassifyModelId,
                    callback: function($$v) {
                      _vm.selectedClassifyModelId = $$v
                    },
                    expression: "selectedClassifyModelId"
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                  _vm._l(_vm.options.classifyModelListOptions, function(
                    option
                  ) {
                    return _c(
                      "option",
                      {
                        key: "ml-edit-class-" + option.value,
                        domProps: { value: option.value }
                      },
                      [_vm._v(" " + _vm._s(option.text) + " ")]
                    )
                  })
                ],
                2
              ),
              _c(
                "select2",
                {
                  attrs: {
                    value: _vm.mlEngineDetailsItem.classifyMlModelDtlId,
                    disabled: !_vm.selectedClassifyModelId
                  },
                  model: {
                    value: _vm.mlEngineDetailsItem.classifyMlModelDtlId,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.mlEngineDetailsItem,
                        "classifyMlModelDtlId",
                        $$v
                      )
                    },
                    expression: "mlEngineDetailsItem.classifyMlModelDtlId"
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                  _vm._l(_vm.options.classifyModelDetailListOptions, function(
                    option
                  ) {
                    return _c(
                      "option",
                      {
                        key: "ml-edit-class-detail-" + option.value,
                        domProps: { value: option.value }
                      },
                      [_vm._v(" " + _vm._s(option.text) + " ")]
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _c("div", { staticClass: "bottom" }, [
            !_vm.isEditMlEngine
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.clickSaveMlEngineButton }
                  },
                  [_vm._v("추가")]
                )
              : _vm._e(),
            _vm.isEditMlEngine
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.clickUpdateMlEngineButton }
                  },
                  [_vm._v("수정")]
                )
              : _vm._e()
          ])
        ])
      ]
    ),
    _c("div", { ref: "mlModelPopup", staticClass: "popup ml-model-popup" }, [
      _c("div", {
        staticClass: "background",
        on: { click: _vm.closeMlModelPopup }
      }),
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "top" }, [
          _vm._v(" ML 모델 관리 "),
          _c("i", {
            staticClass: "fa fa-times",
            on: { click: _vm.closeMlModelPopup }
          })
        ]),
        _c("div", { staticClass: "middle" }, [
          _c("div", { staticClass: "ml-model-list" }, [
            _c("label", [_vm._v("ML 모델")]),
            _c("div", { staticClass: "table" }, [
              _vm._m(17),
              _c(
                "div",
                { ref: "mlModelTbody", staticClass: "tbody" },
                [
                  _vm._l(_vm.mlModelList, function(mlModel, i) {
                    return _c(
                      "div",
                      {
                        key: "mlmodel-" + mlModel.mlModelId,
                        class:
                          "tr " +
                          (i === _vm.selectedMlModelIdx ? "selected" : ""),
                        on: {
                          click: function($event) {
                            return _vm.clickMlModel(i)
                          }
                        }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(mlModel.mlModelId))]),
                        _c("span", [_vm._v(_vm._s(mlModel.mlModelNm))]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.options.mlModelDvsnCdOptions.find(function(
                                mlModelDvsnCd
                              ) {
                                return (
                                  mlModelDvsnCd.cd === mlModel.mlModelDvsnCd
                                )
                              }).cdNm
                            )
                          )
                        ]),
                        _c("span", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              on: {
                                click: function($event) {
                                  return _vm.clickEditMlModelButton(
                                    $event,
                                    mlModel
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-pencil-alt" })]
                          )
                        ])
                      ]
                    )
                  }),
                  _c("i", { staticClass: "fa fa-spin fa-spinner" }),
                  !_vm.mlModelList.length
                    ? _c("div", { staticClass: "empty" }, [
                        _vm._v("조회된 결과가 없습니다")
                      ])
                    : _vm._e()
                ],
                2
              )
            ])
          ]),
          _c("div", { staticClass: "ml-model-detail-list" }, [
            _c("label", [_vm._v("ML 모델 상세")]),
            _c("div", { staticClass: "table" }, [
              _vm._m(18),
              _c(
                "div",
                { ref: "mlModelDetailTbody", staticClass: "tbody" },
                _vm._l(_vm.mlModelDetailList, function(mlModelDetail) {
                  return _c(
                    "div",
                    {
                      key:
                        "ml-detail-" +
                        mlModelDetail.mlModelId +
                        mlModelDetail.mlModelDtlId,
                      staticClass: "tr"
                    },
                    [
                      mlModelDetail.memo
                        ? _c("div", { staticClass: "ml-model-detail-memo" }, [
                            _vm._v(_vm._s(mlModelDetail.memo))
                          ])
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(mlModelDetail.mlModelDtlNm))]),
                      _c("span", [_vm._v(_vm._s(mlModelDetail.mlModelDir))]),
                      _c("span", [_vm._v(_vm._s(mlModelDetail.mlModelFile))]),
                      _c("span", [_vm._v(_vm._s(mlModelDetail.mlModelWeight))]),
                      _c("span", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            on: {
                              click: function($event) {
                                return _vm.clickEditMlModelDetailButton(
                                  $event,
                                  mlModelDetail
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "fa fa-pencil-alt" })]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "bottom" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              on: { click: _vm.clickAddMlModelButton }
            },
            [_c("i", { staticClass: "fa fa-plus" }), _vm._v(" ML 모델 추가 ")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              on: { click: _vm.clickAddMlModelDetailButton }
            },
            [
              _c("i", { staticClass: "fa fa-plus" }),
              _vm._v(" ML 모델 상세 추가 ")
            ]
          )
        ])
      ])
    ]),
    _c(
      "div",
      { ref: "mlModelEditPopup", staticClass: "popup ml-model-edit-popup" },
      [
        _c("div", {
          staticClass: "background",
          on: { click: _vm.closeMlModelEditPopup }
        }),
        _c("div", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "top" }, [
            _vm._v(
              " ML 모델 " + _vm._s(_vm.isEditMlModel ? "수정" : "추가") + " "
            ),
            _c("i", {
              staticClass: "fa fa-times",
              on: { click: _vm.closeMlModelEditPopup }
            })
          ]),
          _c("div", { staticClass: "middle" }, [
            _c("div", [
              _c("label", [_vm._v("모델 명")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mlModelDetailsItem.mlModelNm,
                    expression: "mlModelDetailsItem.mlModelNm"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "모델 명" },
                domProps: { value: _vm.mlModelDetailsItem.mlModelNm },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.mlModelDetailsItem,
                      "mlModelNm",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c(
              "div",
              [
                _c("label", [_vm._v("모델 구분")]),
                _c(
                  "select2",
                  {
                    attrs: {
                      value: _vm.mlModelDetailsItem.mlModelDvsnCd,
                      "data-vv-name": "mlModelDetailsItem.mlModelDvsnCd",
                      options: _vm.options.mlModelDvsnCdOptions
                    },
                    model: {
                      value: _vm.mlModelDetailsItem.mlModelDvsnCd,
                      callback: function($$v) {
                        _vm.$set(_vm.mlModelDetailsItem, "mlModelDvsnCd", $$v)
                      },
                      expression: "mlModelDetailsItem.mlModelDvsnCd"
                    }
                  },
                  [_c("option", { attrs: { value: "" } }, [_vm._v("선택")])]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "bottom" }, [
            !_vm.isEditMlModel
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.clickSaveMlModelButton }
                  },
                  [_vm._v("추가")]
                )
              : _vm._e(),
            _vm.isEditMlModel
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    on: { click: _vm.clickDeleteMlModelButton }
                  },
                  [_vm._v("삭제")]
                )
              : _vm._e(),
            _vm.isEditMlModel
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.clickUpdateMlModelButton }
                  },
                  [_vm._v("수정")]
                )
              : _vm._e()
          ])
        ])
      ]
    ),
    _c(
      "div",
      {
        ref: "mlModelDetailEditPopup",
        staticClass: "popup ml-model-detail-edit-popup"
      },
      [
        _c("div", {
          staticClass: "background",
          on: { click: _vm.closeMlModelDetailEditPopup }
        }),
        _c("div", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "top" }, [
            _vm._v(
              " ML 모델 " +
                _vm._s(_vm.isEditMlModelDetail ? "수정" : "추가") +
                " "
            ),
            _c("i", {
              staticClass: "fa fa-times",
              on: { click: _vm.closeMlModelDetailEditPopup }
            })
          ]),
          _c("div", { staticClass: "middle" }, [
            _c(
              "div",
              [
                _c("label", [_vm._v("모델")]),
                _c(
                  "select2",
                  {
                    attrs: { value: _vm.detailMlModelDetailsItem.mlModelId },
                    model: {
                      value: _vm.detailMlModelDetailsItem.mlModelId,
                      callback: function($$v) {
                        _vm.$set(_vm.detailMlModelDetailsItem, "mlModelId", $$v)
                      },
                      expression: "detailMlModelDetailsItem.mlModelId"
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                    _vm._l(_vm.options.mlModelOptions, function(option) {
                      return _c(
                        "option",
                        {
                          key: "ml-detail-edit-" + option.text + option.value,
                          domProps: { value: option.value }
                        },
                        [_vm._v(" " + _vm._s(option.text) + " ")]
                      )
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c("div", [
              _c("label", [_vm._v("모델 상세 명")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.detailMlModelDetailsItem.mlModelDtlNm,
                    expression: "detailMlModelDetailsItem.mlModelDtlNm"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "모델 상세 명" },
                domProps: { value: _vm.detailMlModelDetailsItem.mlModelDtlNm },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.detailMlModelDetailsItem,
                      "mlModelDtlNm",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", [
              _c("label", [_vm._v("Dir")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.detailMlModelDetailsItem.mlModelDir,
                    expression: "detailMlModelDetailsItem.mlModelDir"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "Dir" },
                domProps: { value: _vm.detailMlModelDetailsItem.mlModelDir },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.detailMlModelDetailsItem,
                      "mlModelDir",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", [
              _c("label", [_vm._v("Config")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.detailMlModelDetailsItem.mlModelFile,
                    expression: "detailMlModelDetailsItem.mlModelFile"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "Config" },
                domProps: { value: _vm.detailMlModelDetailsItem.mlModelFile },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.detailMlModelDetailsItem,
                      "mlModelFile",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", [
              _c("label", [_vm._v("Weight")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.detailMlModelDetailsItem.mlModelWeight,
                    expression: "detailMlModelDetailsItem.mlModelWeight"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "Weight" },
                domProps: { value: _vm.detailMlModelDetailsItem.mlModelWeight },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.detailMlModelDetailsItem,
                      "mlModelWeight",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", [
              _c("label", [_vm._v("메모")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.detailMlModelDetailsItem.memo,
                    expression: "detailMlModelDetailsItem.memo"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "Memo" },
                domProps: { value: _vm.detailMlModelDetailsItem.memo },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.detailMlModelDetailsItem,
                      "memo",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "bottom" }, [
            !_vm.isEditMlModelDetail
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.clickSaveMlModelDetailButton }
                  },
                  [_vm._v("추가")]
                )
              : _vm._e(),
            _vm.isEditMlModelDetail
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    on: { click: _vm.clickDeleteMlModelDetailButton }
                  },
                  [_vm._v("삭제")]
                )
              : _vm._e(),
            _vm.isEditMlModelDetail
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.clickUpdateMlModelDetailButton }
                  },
                  [_vm._v("수정")]
                )
              : _vm._e()
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pageTitle" } }, [
      _c("b", [_vm._v("CCTV 상세")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "text-right",
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
          margin: "0"
        }
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-labeled bg-primary",
            attrs: { type: "submit" }
          },
          [
            _c("b", [_c("i", { staticClass: "icon-search4" })]),
            _vm._v(" 검색 ")
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-file-excel" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-plus3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "panel-title" }, [
      _c("i", { staticClass: "icon-checkmark3 position-left" }),
      _c("b", [_vm._v("상세정보")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "text-semibold" }, [
      _c("i", { staticClass: "icon-cog3 position-left" }),
      _vm._v(" CCTV 정보 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tooltipText" }, [
      _vm._v(" 안전대상물 표시 "),
      _c("br"),
      _vm._v(" *사용: 대상물이 안전한 상태인 경우에도 표시합니다. "),
      _c("br"),
      _vm._v(" *미사용: 대상물이 안전한 상태인 경우에는 표시하지 않습니다. "),
      _c("br"),
      _c("br"),
      _vm._v(" 대상물명표시 "),
      _c("br"),
      _vm._v(" *사용: 판별된 대상의 대상물명과 조치대상명을 표시합니다. "),
      _c("br"),
      _vm._v(" *미사용: 판별된 대상의 조치대상명만 표시합니다. ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "20px 0px 20px 0px" } }, [
      _c("b", [_vm._v("ML 엔진 설정")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "20px 0px 20px 0px" } }, [
      _c("b", [_vm._v("모니터링 설정")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-size": "12px",
          color: "red",
          "margin-bottom": "10px"
        }
      },
      [
        _vm._v(
          " CCTV 접속 URL, CCTV 접속 포트, CCTV PLAY URL, ML 엔진 설정, 모니터링 설정 등의 경우 ML서버 재시작 후 추가 및 수정 내역이 반영됩니다. "
        ),
        _c("br"),
        _vm._v(
          " 원활한 사용을 위해 CCTV 정보 추가 및 수정 후엔 ML서버를 재시작해주세요. "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-cross" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-cross" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-minus3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "thead" }, [
      _c("div", { staticClass: "tr" }, [
        _c("span", [_vm._v("ID")]),
        _c("span", [_vm._v("PORT")]),
        _c("span", [_vm._v("탐지 모델")]),
        _c("span", [_vm._v("분류 모델")]),
        _c("span", [_vm._v("연결")]),
        _c("span")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "thead" }, [
      _c("div", { staticClass: "tr" }, [
        _c("span", [_vm._v("현장")]),
        _c("span", [_vm._v("CCTV ID")]),
        _c("span", [_vm._v("CCTV 이름")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "thead" }, [
      _c("div", { staticClass: "tr" }, [
        _c("span", [_vm._v("모델 ID")]),
        _c("span", [_vm._v("모델 명")]),
        _c("span", [_vm._v("모델 구분")]),
        _c("span")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "thead" }, [
      _c("div", { staticClass: "tr" }, [
        _c("span", [_vm._v("모델 상세 명")]),
        _c("span", [_vm._v("Dir")]),
        _c("span", [_vm._v("Config")]),
        _c("span", [_vm._v("Weight")]),
        _c("span")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }